import React from 'react'
import { Image } from '@farewill/ui'

import HeroWithImage from 'components/HeroWithImage'

const Hero = () => (
  <HeroWithImage
    titleMaxWidth="initial"
    title="We believe in brighter goodbyes"
    subtitle="We’re making the processes around death simpler and more affordable, to help you protect the people you love, and say goodbye your way."
    image={
      <Image
        path="illustrations/cremation-hill"
        width={260}
        widthFromM={466}
        heightFromM={417}
      />
    }
    variant="centered"
  />
)

export default Hero
